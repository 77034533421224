import React from 'react'
import Main from '../components/community/Main'
import Head from '../components/community/Head'
const CommGuide = () => {
  return (
    <div>
        <Head/>
        <Main/>
    </div>
  )
}

export default CommGuide;